import React, { useContext } from "react";
import Gallery from "../components/Gallery";
import { graphql, useStaticQuery } from "gatsby";
import { BGLoadedContext } from "../contexts/BGLoadedProvider";
import "../style.scss";

type DataProps = {
  contentfulPortfolioPage: {
    sections: {
      references: [
        {
          assets: {
            references: [
              {
                assets: [
                  {
                    file: {
                      url: string;
                      fileName: string;
                    };
                    description: string;
                  }
                ];
              }
            ];
          };
          columns: number;
          subheader: null | string;
          type: string;
        }
      ];
    };
    header: string;
  };
};

const ThreeDPage = () => {
  const query: DataProps = useStaticQuery(graphql`
    query {
      contentfulPortfolioPage(
        id: { eq: "b89bdfbc-0ea1-584c-8c00-dd15dc1e4503" }
      ) {
        sections {
          references {
            assets {
              references {
                ... on ContentfulGifset {
                  assets {
                    file {
                      url
                      fileName
                    }
                    description
                    gatsbyImageData(
                      width: 400
                      layout: CONSTRAINED
                      resizingBehavior: SCALE
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            columns
            subheader
            type
          }
        }
        header
      }
    }
  `);
  const refs = query.contentfulPortfolioPage.sections.references;
  const { isBGLoaded } = useContext(BGLoadedContext);
  return (
    <main className="gif-page">
      <h1>{query.contentfulPortfolioPage.header}</h1>
      <section className="gif-section">
        {isBGLoaded &&
          refs.map((ref, index) => (
            <Gallery
              key={index}
              header={ref.subheader}
              type={ref.type}
              columns={ref.columns}
              assets={ref.assets.references[0].assets}
              square={true}
            />
          ))}
      </section>
    </main>
  );
};

export default ThreeDPage;
